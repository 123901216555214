<template>
    <div class="py-5">
        <div class="container">
            <div v-for="(item, n) in data" :key="n" class="block">
                <span class="text-xl font-bold block">{{ item.Title }}</span>
                <b-collapse class="shadow-none" animation="slide" v-for="(collapse, index) of item.Data" :key="index" :open="isOpen === index && indexData === n" @open="isOpen = index, indexData = n">
                    <div slot="trigger" slot-scope="props" class="card-header shadow-none" role="button">
                        <div  class="card-header-title flex p-1">
                            <p class="font-normal">
                                {{ collapse.Title }}
                            </p>
                        </div>
                        <a class="card-header-icon p-1">
                            <b-icon
                                :icon="props.open ? 'menu-down' : 'menu-up'">
                            </b-icon>
                        </a>
                    </div>
                    <div class="card-content">
                        <div class="content">
                            <div v-html="collapse.Description"></div>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Array
        }
    },
    data () {
        return {
            isOpen: -1,
            indexData: -1
        }
    }
}
</script>
